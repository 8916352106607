
























import SiteSection from '@/components/SiteSection.vue'
import { cards } from '@/utils/appCards'
import { defineComponent } from '@vue/composition-api'
import AppCard from './AppCard.vue'

export default defineComponent({
  name: 'SiteSectionApps',

  components: {
    SiteSection,
    AppCard,
  },

  setup() {
    return {
      cards,
    }
  },
})
