var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{attrs:{"tag":"section"}},[_c('BaseBox',{class:['relative', { 'overflow-hidden': _vm.masked }]},[_c('BaseBox',{staticClass:"absolute w-full h-full"},[_vm._t("background")],2),_c('BaseBox',{staticClass:"relative"},[_vm._t("container",function(){return [_c('BaseBox',{class:[_vm.contained ? 'flex justify-center' : '']},[_vm._t("layout-container",function(){return [_c('BaseBox',{class:_vm.contained
                  ? [
                      'max-w-screen-lg',
                      'xl:max-w-screen-xl',
                      '2xl:max-w-screen-2xl',
                      '3xl:max-w-screen-3xl',
                      'w-full' ]
                  : []},[_vm._t("layout",function(){return [_c('BaseBox',{staticClass:"py-14 lg:py-20"},[_vm._t("default")],2)]})],2)]})],2)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }