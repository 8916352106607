






import SiteSectionApps from '@/components/SiteSectionApps.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Home',

  components: {
    SiteSectionApps,
  },
})
